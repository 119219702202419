<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        The Danger of Impotent Vaccines
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746489/pasted_image_0_zhzo6a.png"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            The Danger of Impotent Vaccines
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Nov 24, 2021</p>
            </span>
          </div>
          <p>
            Courtesy of the COVID-19, people have become widely interested in
            the subject of vaccines. And, with the emergence of a variety of
            vaccines available, this development has further led to two
            pronounced divisive categories; the anti-vaxxers and the pro-vaccine
            in the grand scheme of things. On the 23rd of March 2021, in a
            recent statistical report, it was recorded that Google searches for
            COVID-19 vaccinations went up 1,900%, standing at the top-most
            position of queries on the Search Engine.
          </p>
          <p>
            With everyone concerned with the potentials of the current vaccines,
            there is a shift in direction of older vaccines that might have
            begun to lose their efficacies due to abandonment or subjection to
            inappropriate storage conditions. While we have become too focused
            on the issue of the COVID-19 vaccines, let's take a step to
            understand the dangers that expired or impotent vaccines might pose
            if proper care is not taken in the future.
          </p>
          <p>
            Vaccines work to prime your immune system against future “attacks”
            by a particular disease. There are vaccines against both viral and
            bacterial pathogens or disease-causing agents.
          </p>
          <p>
            When a pathogen enters your body, your immune system generates
            antibodies to try to fight it off. Depending on the strength of your
            immune response and how effectively the antibodies fight off the
            pathogen, you may or may not get sick.
          </p>
          <p>
            If you do fall ill, however, some of the antibodies that are created
            will remain in your body playing watchdog after you’re no longer
            sick. If you’re exposed to the same pathogen in the future, the
            antibodies will ”recognize” it and fight it off.
          </p>
          <p>
            Some vaccines against bacteria are made in the form of the bacteria
            itself. In other cases, they may be made with a modified form of a
            toxin generated by the bacteria. Tetanus, for example, is not
            directly caused by the Clostridium tetani bacteria. Instead, its
            symptoms are primarily caused by tetanospasmin, a toxin generated by
            that bacterium. Some bacterial vaccines are therefore made with a
            weakened or inactivated version of the toxin that actually produces
            symptoms of illness. This weakened or inactivated toxin is called a
            toxoid. A tetanus immunization, for example, is made with
            tetanospasmin toxoid.
          </p>

          <p class="font-weight-bold mb-3">
            What are impotent vaccines?
          </p>

          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746485/pasted_image_0_djcmyu.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>

          <p>
            Vaccines are designed to generate an immune response that will
            protect the vaccinated individual during future exposures to the
            disease. Individual immune systems, however, are different enough
            that in some cases, a person’s immune system will not generate an
            adequate response. As a result, he or she will not be effectively
            protected after immunization.
          </p>
          <p>
            That said, the effectiveness of most vaccines are high. After
            receiving the second dose of the MMR vaccine (measles, mumps and
            rubella) or the standalone measles vaccine, 99.7% of vaccinated
            individuals are immune to measles. The inactivated polio vaccine
            offers 99% effectiveness after three doses. Varicella (chickenpox)
            vaccine is between 85% and 90% effective in preventing all varicella
            infections, but 100% effective in preventing moderate and severe
            chickenpox.
          </p>
          <p>
            While there are some vaccines that assume certain percentages of
            effectiveness there are some without a degree of effectiveness.
            Impotent vaccines, like the name implies, are vaccines that have
            become powerless or incapable of performing their immunizing
            functions. This might be either as a result of expiration or
            improper storage.
          </p>
          <p>
            In pharmaceutical parlance, an expired drug might assume a little
            possibility of effect less than a month after expiration. However,
            when giving reference to vaccines, expiration is absolute and usage
            is recommended to stop immediately.
          </p>
          <p>
            In general, expiry dates on vaccines and medicines are extremely
            important to pay attention to. Beyond that date, some of the
            ingredients could be useless, potentially leaving people vulnerable
            to the disease they are supposed to be protected against, or the
            ingredients themselves could become harmful.
          </p>
          <p>
            Vaccines are supposed to serve as immunizing agents against
            diseases, once it loses its effectiveness, this exposes recipients
            to greater risks associated with the ailment.
          </p>
          <p>
            Although, there isn't much evidence to back this statement, there
            have been speculations that when a vaccine loses its power to
            prevent diseases, it might become harmful or could mutate into a
            much more dangerous agent capable of causing more damage to the body
            than the supposed disease it combats.
          </p>
          <p>
            Seeing the effect of impotent vaccines, a majority of the general
            populace of a country might begin to lose trust in future vaccines
            to help safeguard their lives. This will dissolve into a great
            difficulty in convincing citizens to partake in future vaccinations.
          </p>

          <p class="font-weight-bold mb-3">
            How can the danger be averted?
          </p>

          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746484/pasted_image_0_1_xs8u40.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>
          <p>
            In the middle of the scramble to reduce COVID-19 cases worldwide,
            many countries have had their vaccination drives slowed by
            hesitancy, and supply bottlenecks have made it challenging to
            deliver vaccines to the last mile.
          </p>
          <p>
            Developing countries especially are struggling to mitigate the
            impact of the hesitancy on their vaccination process, due to rumors,
            the large populace are quite skeptical about the intentions of mass
            vaccination and effectiveness of donated vaccines while the elites
            of some of these countries are flying their families to more
            developed first world countries to get vaccinated. The situation
            warrants the Government of these countries to get creative and take
            the issue of trust more seriously if they want to see changes in the
            number of people turning out for vaccination.
          </p>
          <p>
            In order to ensure the effectiveness of vaccines being administered
            to the public, especially those arriving at the shores late, leaving
            authority to race against expiration date, credible international
            medical organisations such as WHO as well as Government health
            agencies should play an active role in reviewing and regulating the
            circulation of vaccines. When measures are put in place that could
            speak to the integrity of the vaccines, hesitancy on the side of the
            populace will reduce significantly, thereby speeding up the
            vaccination program.
          </p>
          <p>
            The NPHCDA of Nigeria recently deployed Real-time Data Loggers in
            the distribution of the 4.2million doses of Moderna vaccines given
            to it by the USAID. The Dataloggers were essential in reporting the
            real-time condition of the vaccines as they were being distributed
            to the last mile, after which a quality assurance report was
            generated through the Remote Software used to monitor the live
            condition of the vaccines before administering them to the public.
            Measures like this further improve trust in the vaccination process
            and help to a great deal reduce hesitancy.
          </p>
          <p>
            When it comes to the health of the popular populace, we can't always
            be too careful. In late April 2021, Malawi planned to destroy more
            than 16,000 doses of the AstraZeneca vaccine provided by the African
            Union, because the batch had an expiration date of April 13, 2021.
            South Sudan planned to discard 59,000 doses for the same reason.
            South Africa asked The Serum Institute of India to replace a batch
            of one million doses, which arrived at the start of April 2021 and
            had an expiration date of April 13, 2021.
          </p>
          <p>
            These countries had chosen to discard doses or asked to discard
            doses, despite the fact that the World Health Organization (WHO) had
            advised countries to hold on to the expired doses while it assesses
            if the vaccines could still be used past their set expiration date.
          </p>
          <p>
            In getting vaccines to the last mile in time and reducing the time
            these vaccines spend in store, authorities have to get innovative
            and start employing more effective technology like Smart
            Solar-Recharge Portable Cold Boxes that could be used in delivering
            the vaccines to rural regions with lean infrastructures.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
import image7 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
// import image5 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
import image8 from "../../assets/blog new/pharmaceuticall.png";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image7,
        content:
          "Digitalization in the African retail pharmaceutical sector (With Shelflife by Field intelligence and mPharma)",
        date: "Sept 19, 2021",
        link:
          "/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          "Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "Sept 12, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image8,
        content:
          "Gricd works with ISN to assure product integrity and avert waste risk.",
        date: "Sept 18, 2021",
        link:
          "/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Medical Consultant Speaks On The Role of Storage In Managing Diabetes.",
                item:
                  "https://gricd.com/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
.pic-size {
  width: 28rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
